import React, { Component, Fragment } from 'react'
import { Home } from 'react-feather'
import { Layout, SEO, VerticalCard, HMOSlider } from '../../components/common'
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap'
import ShowBlank from '../shared/ShowBlank'

class Promos extends Component {
  render() {
    const { events, promos } = this.props.pageContext.promosPage
    const { accreditedHmos, topNav } = this.props.pageContext
    const { favicon } = topNav
    return (
      <>
        <SEO title="Promos" favicon={favicon} />
        <Layout activeNav='promos' data={topNav}>
          <Container className="my-4 md-mt-10 sm-mt-7">
            <Breadcrumb className="breadcrumb-wrapper mx-0">
              <Breadcrumb.Item href="/">
                <Home className="text-violet mr-2" size={14} />
                Home
            </Breadcrumb.Item>
              <Breadcrumb.Item active>Promos & Events</Breadcrumb.Item>
            </Breadcrumb>

            <h2 className="text-dark-slate-blue mb-2 text-title mt-3">Promos</h2>
            <Row>
              {
                promos && promos.length > 0
                  ? <Fragment>
                    {promos.map((promo, i) =>
                      <Col lg={4} md={4} sm={12} className="my-3" key={i}>
                        <VerticalCard
                          img_url={promo.banner}
                          title={promo.title}
                          description={promo.shortDescription}
                          slug={"/promo/" + promo.slug}
                        />
                      </Col>
                    )}
                  </Fragment>
                  : <ShowBlank />
              }
            </Row>

            <h2 className="text-dark-slate-blue mb-2 text-title mt-5">Events</h2>
            <Row>
              {
                events && events.length > 0
                  ? <Fragment>
                    {events.map((event, i) =>
                      event.active === "true"
                        ? <Col lg={4} md={4} sm={12} className="my-3" key={i}>
                          <VerticalCard
                            img_url={event.banner}
                            title={event.title}
                            description={event.shortDescription}
                            slug={"/event/" + event.slug}
                          />
                        </Col>
                        : null)}
                  </Fragment>
                  : <ShowBlank />
              }
            </Row>
          </Container>

          <HMOSlider data={accreditedHmos} />
        </Layout>
      </>
    )
  }
}

export default Promos
